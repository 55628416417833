import React from "react"
import { graphql } from "gatsby"

import PostsTagTemplate from "../PostsTagTemplate"

const BlogPostsTag = ({ data: { blogPosts }, pageContext: { tag } }) => {
  return <PostsTagTemplate posts={blogPosts} type="blog" tag={tag} />
}

export default BlogPostsTag

export const query = graphql`
  query BlogPostsTagSlug($tag: [String]) {
    blogPosts: allContentfulBlogPost(
      filter: { slug: { ne: "placeholder" }, tags: { in: $tag } }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
  }
`
